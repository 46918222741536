import styled, {css} from "styled-components";
import {Input as antInput} from "antd";

const Input = styled(antInput)(({theme: {form: {input}}}) => css`
    padding: 18px;
    background: ${input.background} !important;
    border-radius: 10px;
    font-size: 14px;
    max-height: 55px;
    color: ${input.color};
    box-shadow: ${input.boxShadow};

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        -webkit-background-clip: text;
        -webkit-text-stroke-color: ${input.color} !important;
        -webkit-text-fill-color: ${input.color} !important;
        transition: background-color 999999s ease-in-out 0s;
        box-shadow: inset 0 0 20px 20px ${input.background} !important;
    }

    input {
        padding: 18px;
        background: ${input.background};
        font-size: 14px;
        max-height: 55px;
        color: ${input.color};
    }

    &:hover {
        background: ${input.background};
    }
`);

Input.Password = styled(antInput.Password)(({theme: {form: {input}}}) => css`
  padding: 18px;
  background: ${input.background};
  border-radius: 10px;
  font-size: 14px;
  max-height: 55px;
  box-shadow: ${input.boxShadow};

  input {
    color: ${input.color};

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
          -webkit-background-clip: text;
          -webkit-text-stroke-color: ${input.color} !important;
          -webkit-text-fill-color: ${input.color} !important;
          transition: background-color 999999s ease-in-out 0s;
          box-shadow: inset 0 0 20px 20px ${input.background} !important;
      }
  }

  &:hover {
    background: ${input.background};
  }
`);

export default Input;
